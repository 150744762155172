import React from 'react'
import loading from "../img/Loading_icon.gif"
const Loader = () => {
  return (
    <div>
        <img src={loading} alt="loading" />
    </div>
  )
}

export default Loader